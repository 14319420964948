/* ListUser.css */
.user-table-container {
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1f1f1f;
    color: white;
    margin-top: 20px;
  }
  
  .user-table th,
  .user-table td {
    text-align: left;
    padding: 12px 15px;
  }
  
  .user-table th {
    background-color: #333;
    color: #fff;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #2c2c2c;
  }
  
  @media screen and (max-width: 600px) {
    .user-table thead {
      display: none;
    }
  
    .user-table,
    .user-table tbody,
    .user-table tr,
    .user-table td {
      display: block;
      width: 100%;
    }
  
    .user-table tr {
      margin-bottom: 15px;
    }
  
    .user-table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }
  
    .user-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .badge {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 999px;
        font-size: 14px;
        font-weight: bold;
      }
      
      /* Badge vert pour Enabled */
      .enabled-badge {
        background-color: #4caf50;
        color: white;
      }
      
      /* Badge rouge pour Disabled */
      .disabled-badge {
        background-color: #f44336;
        color: white;
      }
      
      /* Badge orange pour ADP */
      .adp-badge {
        background-color: #ff9800;
        color: white;
      }
      
      /* Badge bleu clair pour Classic */
      .classic-badge {
        background-color: #64b5f6;
        color: white;
      }
  }
  