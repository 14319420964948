/* UserStatus.css */
.user-status-list {
    list-style: none;
    padding: 0;
    margin: 10px;
  }
  
  .user-status-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #ffffff; /* Name Colors */
    position: relative;
  }
  
  .user-status-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  
  .user-status-name {
    font-weight: bold;
    flex-grow: 1;
  }
  
  .user-status-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
    right: 165px;
    transform: translate(50%, 50%);
    border: 2px solid #2c2f33;
    z-index: 2;
  }
  
  .user-status-indicator.n { /* Reponse WS 'N' = Online */
    background-color: #23a55a;
  }

  .user-status-indicator.f { /* Reponse WS 'F' = Offline */
    background-color: #80848e;
  }

  .user-status-indicator.d  { /* Reponse WS 'D' = Idle */
    background-color: #f0b232;
  }

  .user-status-indicator.u  { /* Reponse WS 'D' = Phone Online */
    background-color: #593695;
  }