@import url(https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* UserStatus.css */
.user-status-list {
    list-style: none;
    padding: 0;
    margin: 10px;
  }
  
  .user-status-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #ffffff; /* Name Colors */
    position: relative;
  }
  
  .user-status-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  
  .user-status-name {
    font-weight: bold;
    flex-grow: 1;
  }
  
  .user-status-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
    right: 165px;
    transform: translate(50%, 50%);
    border: 2px solid #2c2f33;
    z-index: 2;
  }
  
  .user-status-indicator.n { /* Reponse WS 'N' = Online */
    background-color: #23a55a;
  }

  .user-status-indicator.f { /* Reponse WS 'F' = Offline */
    background-color: #80848e;
  }

  .user-status-indicator.d  { /* Reponse WS 'D' = Idle */
    background-color: #f0b232;
  }

  .user-status-indicator.u  { /* Reponse WS 'D' = Phone Online */
    background-color: #593695;
  }
/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@keyframes jump {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }  

.networkStatsContainer {
    position: relative;
    top: -65%;
    left: 0;
  }
  
.server-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white; /* Définir la couleur du texte en blanc */
    transition: all 0.3s ease-in-out;
  }
  
  .server-card:hover {
    box-shadow: 0 4px 15px rgba(255, 165, 0, 0.3);
  }
  
  .server-name {
    flex: 1 1;
    text-align: left;
    margin-left: 20px; /* Décalage vers la droite */
  }
  
  .server-ip {
    flex: 1 1;
    text-align: center;
  }
  
  .server-status {
    flex: 0 1;
    padding: 5px 10px;
    border-radius: 12px;
    color: #fff;
    margin-right: 20px;
  }
  
  .server-status.running {
    background-color: #28a745;
  }

  .server-status.starting {
    background-color: #ffc800;
  }

  .server-status.stopping {
    background-color: #ffc800;
  }
  
  .server-status.offline {
    background-color: #dc3545;
  }
  
.server-name {
    position: relative;
    display: flex;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem;
  }
    
.xterm-container {
    width: 1030px; /* Largeur spécifique */
    height: 600px; /* Hauteur spécifique */
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 30%;
    background-color: black; /* Couleur de fond */
    border-radius: 8px; /* Coins arrondis */
    margin: 0 auto; /* Centrage horizontal */
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Ombre */
}

.power-button-group {
    display: flex;
    position: relative;
    justify-content: flex-end;
    grid-gap: 30px;
    gap: 30px;
    margin-right: 20px;
    flex-direction: column;
    width : auto;
    grid-gap: 10px;
    gap: 10px;
    padding: 5px;
    top: auto;
    bottom: 70px;
    right: 10px;
    left: auto;
    white-space: nowrap;
}

.power-button {
    background-color: #1A73E8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.power-button:hover {
    background-color: #165EAB;
}

.stats-group {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-end;
}

.terminal-controls-container {
    margin-right: 20px;
}

.control-panel {
    width : auto;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: flex-end;
    margin-right: 150px;
}

.command-container {
    position: fixed;
    bottom: 120px;
    left: 297px;
    z-index: 1000;
  }
  
  .command-select {
    background-color: #333;
    color: white;
    padding: 8px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }
  
  .command-input {
    width: 843px;
    background-color: #555;
    color: white;
    padding: 8px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
  }
  
  .command-input:focus {
    box-shadow: 0 0 10px orange;
  }
  
.stats-group {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    margin-left: 50px;

}

.stats-server-box, .stats-chart-box {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 16px;
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
  }

  .stats-server-box {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(247, 2, 2, 0.909);
    padding: 16px;
    margin-bottom: 5px;
    position: relative;
    top: auto;
    bottom: 60px;
    right: 80px;
    left: auto;   
  }
  
  .stats-chart-box {
    width : 110%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(160, 8, 140, 0.909);
    padding: 5px;
    margin-bottom: 0px;
    position: relative;
    top: auto;
    bottom: 60px;
    right: auto;
    left: auto;

  }

.xterm-container + .power-button-group {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    margin-right: 150px;
}
.xterm {
    height: 400% !important;
    width: 100% !important;
    cursor: text;
    position: relative;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    font-family: 'Montserrat', monospace;
}

.xterm.focus,
.xterm:focus {
    outline: none;
}

.xterm .xterm-helpers {
    position: absolute;
    top: 0;
    z-index: 4000;
}

.xterm .xterm-helper-textarea {
    padding: 0;
    border: 0;
    margin: 0;
    position: absolute;
    opacity: 0;
    left: -9999em;
    top: 0;
    width: 0;
    height: 0;
    z-index: 1000;
    white-space: nowrap;
    overflow: hidden;
    resize: none;
}

.xterm .composition-view {
    background: #000;
    color: #FFF;
    display: none;
    position: absolute;
    white-space: nowrap;
    z-index: 1000;
}

.xterm .composition-view.active {
    display: block;
}

.xterm .xterm-viewport {
    background-color: #000;
    overflow-y: scroll;
    cursor: default;
    position: absolute;
    width: 145% !important;
    height: 150% !important;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.xterm .xterm-screen {
    height: 345% !important;
    width: 145% !important;
    position: relative;
    cursor: default;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.xterm-dom-renderer-owner-1 {
    width: 145%;
    height: 150%;
    position: relative;
    right: 110%;
    left: 0;
    top: 0;
    bottom: 0;
}

.xterm .xterm-screen canvas {
    width: 145%;
    height: 150%;
    position: relative;
    right: 110%;
    left: 0;
    top: 0;
    bottom: 0;
}

.xterm .xterm-scroll-area {
    visibility: hidden;
}

.xterm-char-measure-element {
    display: inline-block;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: -9999em;
    line-height: normal;
}

.xterm.enable-mouse-events {
    cursor: default;
}

.xterm.xterm-cursor-pointer,
.xterm .xterm-cursor-pointer {
    cursor: pointer;
}

.xterm.column-select.focus {
    cursor: crosshair;
}

.xterm .xterm-accessibility,
.xterm .xterm-message {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    color: transparent;
}

.xterm .live-region {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.xterm-dim {
    opacity: 0.5;
}

.xterm-underline-1 { text-decoration: underline; }
.xterm-underline-2 { -webkit-text-decoration: double underline; text-decoration: double underline; }
.xterm-underline-3 { -webkit-text-decoration: wavy underline; text-decoration: wavy underline; }
.xterm-underline-4 { -webkit-text-decoration: dotted underline; text-decoration: dotted underline; }
.xterm-underline-5 { -webkit-text-decoration: dashed underline; text-decoration: dashed underline; }

.xterm-strikethrough {
    text-decoration: line-through;
}

.xterm-screen .xterm-decoration-container .xterm-decoration {
	z-index: 6;
	position: absolute;
}

.xterm-decoration-overview-ruler {
    z-index: 7;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.xterm-decoration-top {
    z-index: 2;
    position: relative;
}
/* OneProviderInvoiceList.css */
.invoice-table-container {
    overflow-x: auto;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1f1f1f;
    color: white;
    margin-top: 20px;
  }
  
  .invoice-table th,
  .invoice-table td {
    text-align: left;
    padding: 12px 15px;
  }
  
  .invoice-table th {
    background-color: #333;
    color: #fff;
  }
  
  .invoice-table tr:nth-child(even) {
    background-color: #2c2c2c;
  }
  
  .status.paid {
    color: #4caf50;
  }
  
  .status.unpaid {
    color: #f44336;
  }
  
  .status.cancelled {
    color: #ff9800;
  }
  
  .view-invoice-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .view-invoice-btn:hover {
    background-color: #43a047;
  }
  
  @media screen and (max-width: 600px) {
    .invoice-table thead {
      display: none;
    }
  
    .invoice-table,
    .invoice-table tbody,
    .invoice-table tr,
    .invoice-table td {
      display: block;
      width: 100%;
    }
  
    .invoice-table tr {
      margin-bottom: 15px;
    }
  
    .invoice-table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }
  
    .invoice-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  
/* ListUser.css */
.user-table-container {
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1f1f1f;
    color: white;
    margin-top: 20px;
  }
  
  .user-table th,
  .user-table td {
    text-align: left;
    padding: 12px 15px;
  }
  
  .user-table th {
    background-color: #333;
    color: #fff;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #2c2c2c;
  }
  
  @media screen and (max-width: 600px) {
    .user-table thead {
      display: none;
    }
  
    .user-table,
    .user-table tbody,
    .user-table tr,
    .user-table td {
      display: block;
      width: 100%;
    }
  
    .user-table tr {
      margin-bottom: 15px;
    }
  
    .user-table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }
  
    .user-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
    .badge {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 999px;
        font-size: 14px;
        font-weight: bold;
      }
      
      /* Badge vert pour Enabled */
      .enabled-badge {
        background-color: #4caf50;
        color: white;
      }
      
      /* Badge rouge pour Disabled */
      .disabled-badge {
        background-color: #f44336;
        color: white;
      }
      
      /* Badge orange pour ADP */
      .adp-badge {
        background-color: #ff9800;
        color: white;
      }
      
      /* Badge bleu clair pour Classic */
      .classic-badge {
        background-color: #64b5f6;
        color: white;
      }
  }
  
