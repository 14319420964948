/* OneProviderInvoiceList.css */
.invoice-table-container {
    overflow-x: auto;
  }
  
  .invoice-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #1f1f1f;
    color: white;
    margin-top: 20px;
  }
  
  .invoice-table th,
  .invoice-table td {
    text-align: left;
    padding: 12px 15px;
  }
  
  .invoice-table th {
    background-color: #333;
    color: #fff;
  }
  
  .invoice-table tr:nth-child(even) {
    background-color: #2c2c2c;
  }
  
  .status.paid {
    color: #4caf50;
  }
  
  .status.unpaid {
    color: #f44336;
  }
  
  .status.cancelled {
    color: #ff9800;
  }
  
  .view-invoice-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .view-invoice-btn:hover {
    background-color: #43a047;
  }
  
  @media screen and (max-width: 600px) {
    .invoice-table thead {
      display: none;
    }
  
    .invoice-table,
    .invoice-table tbody,
    .invoice-table tr,
    .invoice-table td {
      display: block;
      width: 100%;
    }
  
    .invoice-table tr {
      margin-bottom: 15px;
    }
  
    .invoice-table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }
  
    .invoice-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  