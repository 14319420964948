.server-card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white; /* Définir la couleur du texte en blanc */
    transition: all 0.3s ease-in-out;
  }
  
  .server-card:hover {
    box-shadow: 0 4px 15px rgba(255, 165, 0, 0.3);
  }
  
  .server-name {
    flex: 1;
    text-align: left;
    margin-left: 20px; /* Décalage vers la droite */
  }
  
  .server-ip {
    flex: 1;
    text-align: center;
  }
  
  .server-status {
    flex: 0;
    padding: 5px 10px;
    border-radius: 12px;
    color: #fff;
    margin-right: 20px;
  }
  
  .server-status.running {
    background-color: #28a745;
  }

  .server-status.starting {
    background-color: #ffc800;
  }

  .server-status.stopping {
    background-color: #ffc800;
  }
  
  .server-status.offline {
    background-color: #dc3545;
  }
  